export const LOGO: string = '/assets/images/mentalo-logo.png';
export const MINI_LOGO: string = '/assets/images/mentalo-mini-logo.png';
export const HEADER_BACKGROUND_WELCOME: string = '/assets/images/header-background-welcome.png';
export const HEADER_BACKGROUND_MAIN: string = '/assets/images/header-background-main.svg';

export const ICON_SHIELD_CHECKMARK: string = '/assets/icon/shieldCheckmark.png';
export const ICON_MOUNTAIN: string = '/assets/icon/montagne_navbar.png';
export const ICON_QUESTION_LIST: string = '/assets/icon/questionnaire_navbar.png';
export const ICON_GIFT: string = '/assets/icon/cadeau_navbar.png';
export const ICON_PAPER_PLANE: string = '/assets/icon/invitatons_navbar.png';
export const ICON_AVATAR: string = '/assets/icon/profil_navbar.png';

export const MOUNTAIN: string = '/assets/images/landscapes/mountain.png';
export const SUN: string = '/assets/images/landscapes/sun.png';
export const EAGLE: string = '/assets/images/landscapes/eagle.png';
export const RAINBOW: string = '/assets/images/landscapes/rainbow.png';
export const FLOWERS: string = '/assets/images/landscapes/flowers.png';
export const ANIMALS: string = '/assets/images/landscapes/animals.png';
export const GREENERY: string = '/assets/images/landscapes/greenery.png';
export const FIREWORKS: string = '/assets/images/landscapes/firework.png';
export const NIGHT: string = '/assets/images/landscapes/night.png';
export const VICTORY_HIKER: string = '/assets/images/landscapes/victory_hiker.png';
export const ROAD: string = '/assets/images/landscapes/road.png';

export const HIKER: string = '/assets/images/landscapes/hiker.png';

export const EMAIL: string ='/assets/images/social/email.png';
export const LINK: string ='/assets/images/social/link.png';
export const SMS: string ='/assets/images/social/sms.png';

export const FIREWORK: string ='/assets/icon/firework.png';


export const INSTAGRAM: string ='assets/images/social/instagram.png';
export const WHATSAPP: string ='assets/images/social/whatsapp.png';
export const SNAPCHAT: string ='assets/images/social/snapchat.png';
export const TWITTER: string ='assets/images/social/twitter.png';
export const MESSENGER: string ='assets/images/social/messenger.png';

export const ARROW1: string ='assets/images/arrow/Arrow1.png'
export const ARROW2: string ='assets/images/arrow/Arrow2.png'
export const ARROW3: string ='assets/images/arrow/Arrow3.png'
export const ARROW4: string ='assets/images/arrow/Arrow4.png'
export const ARROW5: string ='assets/images/arrow/Arrow5.png'
export const ARROW6: string ='assets/images/arrow/Arrow6.png'
export const ARROW7: string ='assets/images/arrow/Arrow7.png'

export const ANDROIDTUTO1: string ='assets/images/addtohomescreen/AndroidTuto1.png'
export const ANDROIDTUTO2: string ='assets/images/addtohomescreen/AndroidTuto2.png'
export const IOSTUTO1: string ='assets/images/addtohomescreen/IosTuto1.png'
export const IOSTUTO2: string ='assets/images/addtohomescreen/IosTuto2.png'

export const LOGOECEVE: string ='assets/images/logo_eceve.png'
export const LOGOPARISUNI: string ='assets/images/logo_paris_uni.png'
export const LOGOSANTE: string ='assets/images/logo_minist_sante.png'
export const LOGORECHERCHE: string ='assets/images/logo_minist_recherche.png'
export const LOGOINSERM: string ='assets/images/logo_inserm.png'