import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media (min-width: 320px) and (max-width: 400px) {

  height : 82vh;
  
}

@media (min-width: 768px) and (max-width: 1024px) {
  
  transform: scale(1)
  
}
`

export default Container;