import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { enterOutline, person, textOutline } from "ionicons/icons";
import styled from "styled-components";
import Button from "../../../atoms/Button";
import Gap from "../../../atoms/Gap";
import { LOGOECEVE, LOGOINSERM, LOGOPARISUNI, LOGORECHERCHE, LOGOSANTE } from "../../../../constants/images";
import Title from "../../../atoms/Title";
import AnonymousData from "../../../molecules/Home/Consent/DisplayAnonymousData";

import VeriticalCenter from "../../../atoms/VerticalCenter";
import Container from "../../../atoms/Container";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import { useConfig } from "../../../../contexts/ConfigContext";
import { UserStatus, WelcompeStepEnum } from "../../../../types/welcome";
import { black } from "../../../../theme/theme";
import { useResonanceLayout } from '../../../organisms/ResonnanceLayout/ResoncanceContext';
import { cpuUsage } from "process";

const DisplayHasAccount = (): ReactElement => {
  const { t } = useTranslation();

  const { welcomeData, setCurrentStep, createUser, goPrevious } =
    useHomeContext();
  const { selectedRole, setRole } = useConfig();
  const userStatus = welcomeData.invitations?.to_user?.user_status as string;

  const { setResonanceLayout } = useResonanceLayout();

  useEffect(() => {
    setResonanceLayout({ title: "", welcomePage: true});
  }, []);

  const onAccountClick = (): void => setCurrentStep(WelcompeStepEnum.LOGIN);
  const onNewClick = () => { 
    createUser(selectedRole);
  }
  const invitation_token = window.location.search.includes("invitation=");
  const isActive = userStatus === UserStatus.ACTIVE
  const invitSignOnly = !selectedRole?.attributes?.can_sign_up_only_with_invitation || invitation_token;

  return (
    <Container>
      <VeriticalCenter>
        <ScreenTitle>{t("home_welcome")}</ScreenTitle>
        {
          (invitSignOnly && !isActive) && (
            <>
              <Description>{t("home_description")}</Description>
              <Gap size="2.5%"/>
              <Title>{t("home_new")}</Title>
              <LoginButton onClick={onNewClick} icon={person}>
                {t("home_has_account_false")}
              </LoginButton>
            </>
          )
        }
        <br />
        <RegistredButton onClick={onAccountClick} icon={enterOutline}>
          {t("home_has_account_true")}
        </RegistredButton>

        <Gap size="10%" />
        <AnonymousData />

        <LogoContainer>
        <Logo src={LOGOECEVE} />
        <Logo src={LOGOINSERM} />
        <Logo src={LOGOPARISUNI} />
        <Logo src={LOGOSANTE} />
        <Logo src={LOGORECHERCHE} />

        </LogoContainer>

        <Text>En t'inscrivant, tu acceptes nos <Underline>conditions générales d'utilisation.</Underline></Text>

      </VeriticalCenter>
    </Container>
  );
};

const Underline = styled.span`
  text-decoration : underline;
`

const Text = styled.p`
  text-align: center;
  font-size: 10px;
`

const Logo = styled.img`
  height: auto;
  width: 8%; 

  @media (min-width: 320px) and (max-width: 480px) {
  
  width : 15%;
}
`

const LogoContainer= styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100px;
`

const LoginButton = styled(Button)`
  background-color: ${(props) => props.theme.secondary.main};
  color: ${(props) => props.theme.primary.contrast};
  margin: 0 auto;
  padding: 0 50px;
`;

const Description = styled.div`
  font-size: 18px;
  text-align: center;
  margin: 0 5% 0 5%;
`;

const ScreenTitle = styled.h1`
  text-align: center;
  color: #393939;
  font-weight: bold;
  margin-bottom: 20px;
`

const RegistredButton = styled(Button)`
  background-color: ${(props) => props.theme.primary.background};
  color: ${black};
  margin: 0 auto;
  padding: 0 50px;
  /* width: 80%; */
`;
export default DisplayHasAccount;
